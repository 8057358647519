import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: '登录',
        meta: {
            title: '成都宅居易科技后台管理系统'
        },
        component: () => import('../views/Login.vue')
    },
    // {
    //     path: '/test',
    //     name: '测试',
    //     meta: {
    //         title: '测试'
    //     },
    //     component: () => import('../views/test.vue')
    // },
    //下面路径映射分了children和菜单的分级无关，只是说明下面的所有路径映射都是再/首页中的，所以写道了childern里
    {
        path: '/',
        name: '首页',
        meta: {
            title: '成都宅居易科技后台管理系统'
        },
        component: () => import('../views/HomeView.vue'),
        children: [
            {
                path: '/menu/menu',
                name: '菜单管理',
                component: () => import('../views/menu/menu.vue'),
            },
            {
                path: '/bindex/admininfo',
                name: '用户信息管理',
                component: () => import('../views/usermanage/userinfos.vue'),
            },
            {
                path: '/role/role',
                name: '角色管理',
                component: () => import('../views/role/role.vue'),
            },
            {
                path: '/configuration/configuration',
                name: '配置项管理',
                component: () => import('../views/configuration/configuration.vue'),
            },
            {
                path: '/role/hotWord',
                name: '热搜词管理',
                component: () => import('../views/role/hotWord.vue'),
            },
            {
                path: '/role/senWord',
                name: '敏感词管理',
                component: () => import('../views/role/senWord.vue'),
            },
            {
                path: '/usermanage/usermanage',
                name: '管理员管理',
                component: () => import('../views/usermanage/usermanage.vue'),
            },
            {
                path: '/usermanage/consumer',
                name: '用户管理',
                component: () => import('../views/usermanage/consumer.vue'),
            },
            {
                path: '/usermanage/userlev',
                name: '会员等级管理',
                component: () => import('../views/usermanage/userlev.vue'),
            },
            {
                path: '/goods/brand',
                name: '品牌管理',
                component: () => import('../views/goodsmanage/brand.vue'),
            },
            {
                path: '/goods/category',
                name: '分类管理',
                component: () => import('../views/goodsmanage/category.vue'),
            },
             {
                path: '/goods/unit',
                name: '单位管理',
                component: () => import('../views/goodsmanage/unit.vue'),
            },
            {
                path: '/goods/spec',
                name: '规格管理',
                component: () => import('../views/goodsmanage/spec.vue'),
            },
            {
                path: '/goods/goods',
                name: '商品管理',
                component: () => import('../views/goodsmanage/goods.vue'),
            },
            {
                path: '/goods/gooDetail',
                name: '商品详情',
                component: () => import('../views/goodsmanage/goodDetail.vue'),
                meta:{isfan:true}
            },
            {
                path: '/project/project',
                name: '项目管理',
                component: () => import('../views/project/project.vue'),
            },
            {
                path: '/project/projectDetail',
                name: '项目详情',
                component: () => import('../views/project/projectDetail.vue'),
                meta:{isfan:true}
            },
            {
                path: '/artisan/artisancat',
                name: '工匠分类管理',
                component: () => import('../views/artisan/artisancat.vue'),
            },
            {
                path: '/artisan/steward',
                name: '工匠管理',
                component: () => import('../views/artisan/steward.vue'),
            },
            {
                path: '/project/servelist',
                name: '服务列表',
                component: () => import('../views/project/servelist.vue'),
            },
            {
                path: '/project/majorServe',
                name: '装修服务列表',
                component: () => import('../views/project/majorServe.vue'),
            },
            {
                path: '/project/majorServeDetail',
                name: '装修服务详情',
                meta:{isfan:true},
                component: () => import('../views/project/majorServeDetail.vue'),
            },
            {
                path: '/build/build',
                name: '工地管理',
                meta:{isfan:true},
                component: () => import('../views/build/build.vue'),
            },
            {
                path: '/build/buildDetail',
                name: '工地详情',
                meta:{isfan:true},
                component: () => import('../views/build/buildDetail.vue'),
            },
            {
                path: '/build/category',
                name: '工地类别管理',
                meta:{isfan:true},
                component: () => import('../views/build/category.vue'),
            },
            {
                path: '/post/postlist',
                name: '订单管理',
                meta:{keepAlive:true},
                component: () => import('../views/post/postlist.vue'),
            },
            {
                path: '/post/postlistdata',
                name: '订单汇总',
                meta:{keepAlive:true},
                component: () => import('../views/post/postlistdata.vue'),
            },
            {
                path: '/post/postdetail',
                name: '订单详情',
                meta:{isfan:true},
                component: () => import('../views/post/postdetail.vue'),
            },
            {
                path: '/department/departmentlist',
                name: '部门管理',
                component: () => import('../views/department/departmentlist.vue'),
            },
            {
                path: '/artisan/test',
                name: '测试',
                meta:{isfan:true},
                component: () => import('../views/artisan/test.vue'),
            },
           
        ]
    },
    {
        path: '/binding/binding',
        name: '管理员绑定',
        component: () => import('../views/binding/binding.vue'),
        redirect: '/binding/bindbnt',
        children: [
            {
                path: '/binding/bindbnt',
                name: '绑定首页',
                component: () => import('../views/binding/component/bind.vue'),
            },
            {
                path: '/binding/bindlogin',
                name: '管理员绑定',
                component: () => import('../views/binding/component/login.vue'),
            }
        ]
    },

]

const router = new VueRouter({
    //mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
