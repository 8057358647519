import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import Print from 'vue-print-nb'

// import VueAxios from 'vue-axios'
Vue.use(Print); //注册
Vue.config.productionTip = false
Vue.prototype.axios = axios;
Vue.config.productionTip = false
Vue.use(ElementUI)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
Vue.directive('has', {
  bind: function (el, binding, vnode) {
      // 获取页面按钮权限
      let btnPermissionsStr = binding.value
      let isExist = false;
      // 获取用户按钮权限
      let btnPermissionsArr = JSON.parse(sessionStorage.getItem("userroles")).permissionValueList;
      if (btnPermissionsArr.indexOf(btnPermissionsStr) > -1) {
          isExist = true;
      }
      if (!isExist) {
          el.style = 'display:none';
      }
  }
});
Vue.directive('debounce', {
  inserted(el, binding) {
    el.addEventListener('click', e => {
      el.classList.add('is-disabled')
      el.disabled = true
      setTimeout(() => {
        el.disabled = false
        el.classList.remove('is-disabled')
      }, 2000)//我这里设置的是2000毫秒也就是2秒
    })
  }
})
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
      document.title = to.meta.title
  }
  if(to.path.toLowerCase() === '/login'){
    next()
  }else{
    if(!sessionStorage.getItem('token')&&to.path.indexOf('/binding') ==-1&&to.path.indexOf('/test') ==-1) {
      next({
          path:'/login'
      }) //router文件夹下index.js文件里定义了/login对应的映射q
      return
    }else{
      next()
    }
  }
  //如果本地 存在 token 则 不允许直接跳转到 登录页面
  if(to.fullPath === "/Login"){
    if(sessionStorage.getItem('token')){
      next({
        path:from.fullPath
      });
    }else {
      next();
    }
  }
})
